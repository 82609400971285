<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(locale, i) in $i18n.availableLocales"
        :key="i"
        link
        @click="select(locale)"
      >
      <v-list-item-icon>
        <h3>{{ flag(locale) }}</h3>
      </v-list-item-icon>
        <v-list-item-title>{{ languageName(locale) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { countryCodeEmoji } from 'country-code-emoji';

export default {
  name: 'LanguageSwitcher',
  methods: {
    flag(locale) {
      const country = locale.split('-')[1];
      return countryCodeEmoji(country);
    },
    languageName(locale) {
      const shortLocale = locale.split('-')[0];
      return this.$t(`components.shared.languageSwitcher.${shortLocale}`);
    },
    select(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
